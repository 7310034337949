import React from "react";
import { CardProps as AntCardProps } from "antd";
import { defaultPaddings } from "@common/constants";
import { useResponsive } from "@common/hooks/useResponsive";
import * as S from "./Card.styles";

export interface CardProps extends AntCardProps {
  className?: string;
  padding?: string | number | [number, number];
  autoHeight?: boolean;
}

const Card: React.FC<CardProps> = ({
  className,
  padding,
  size,
  autoHeight = true,
  children,
  ...props
}) => {
  const { isTablet, isDesktop } = useResponsive();

  return (
    <S.Card
      size={size ? size : isTablet ? "default" : "small"}
      className={className}
      bordered={false}
      $padding={
        padding || padding === 0
          ? padding
          : (isDesktop && defaultPaddings.desktop) ||
            (isTablet && defaultPaddings.tablet) ||
            defaultPaddings.mobile
      }
      $autoHeight={autoHeight}
      {...props}
    >
      {children}
    </S.Card>
  );
};

export default Card;
