import React from "react";
import * as S from "./Section.style";
import * as CommonStyles from "@styles/CommonStyles.style";
import { Col } from "antd";

interface ISectionHeaderProps {
  title: string;
  subtitle: string;
}

const SectionHeader: React.FC<ISectionHeaderProps> = ({ title, subtitle }) => {
  return (
    <CommonStyles.FullyCenteredRow>
      <Col span={24}>
        <CommonStyles.FullyCenteredRow style={{ margin: 0 }}>
          <CommonStyles.Sn1Text $isLight> {subtitle}</CommonStyles.Sn1Text>
        </CommonStyles.FullyCenteredRow>
      </Col>

      <Col span={24}>
        <CommonStyles.FullyCenteredRow style={{ margin: 0 }}>
          <CommonStyles.Sn1Title style={{ margin: 0 }}>
            {title}
          </CommonStyles.Sn1Title>
        </CommonStyles.FullyCenteredRow>
      </Col>
    </CommonStyles.FullyCenteredRow>
  );
};

export default SectionHeader;
