import styled from "styled-components";
import { Col, Radio } from "antd";
import { BORDER_RADIUS } from "@app/common/constants";

export const FlagWrapper = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 1.625rem;
  min-height: 1.125rem;
  border-radius: ${BORDER_RADIUS};
  transition: 0.5s ease;

  &:hover {
    opacity: 0.9;
    background-color: var(--flag-icon-bg-color-hover);
  }
`;
