import React from "react";
import * as S from "./MainContent.style";

interface IMainContent {
  children: React.ReactElement | null;
}

const MainContent: React.FC<IMainContent> = ({ children }) => {
  return <S.MainContentWrapper id="main-content">{children}</S.MainContentWrapper>;
};

export default MainContent;
