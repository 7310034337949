import { Row } from "antd";
import styled, { css } from "styled-components";

interface ILinkContainerProps {
  $color?: string;
}

export const LinkContainer = styled(Row)<ILinkContainerProps>`
  justify-content: center;
  align-items: center;
  margin: 1rem 1rem;
  text-align: center;

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  &::after {
    content: "";
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--secondary-color);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  ${(props) =>
    props.$color
      ? css`
          & a {
            color: ${props.$color};
          }
          &:hover {
            & a {
              color: var(--secondary-color);
            }
          }
        `
      : ""}
`;
