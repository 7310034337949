import React, { useCallback } from "react";
import { Col } from "antd";
import { Direction } from "@common/enums";
import { FeatureItem } from "@components/FeatureItem";
import SectionHeader from "@components/SectionHeader/SectionHeader";
import MappingScreenshot from "@assets/images/mapping.png";
import AnalyticsScreenshot from "@assets/images/analytics.png";
import VideoScreenshot from "@assets/images/video.png";
import FilteringScreenshot from "@assets/images/filtering.png";
import {
  AppstoreOutlined,
  BookOutlined,
  CodeOutlined, DashboardOutlined,
  DatabaseOutlined, EnvironmentOutlined,
  EyeOutlined, FieldTimeOutlined, GlobalOutlined,
  HighlightOutlined,
  PieChartOutlined,
  ProjectOutlined, RadarChartOutlined,
  ShareAltOutlined,
  TeamOutlined, VideoCameraOutlined,
} from "@ant-design/icons";
import { FeatureItemType } from "@app/common/types";
import * as CommonStyles from "@styles/CommonStyles.style";
import { useTranslation } from "react-i18next";
import { useLanguage } from "@app/common/hooks/useLanguage";

interface IFeaturesProps {
  id: string;
}

const Features: React.FC<IFeaturesProps> = ({ id }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const getFeatureItems = useCallback((): FeatureItemType[] => {
    return [
      {
        title: t(
          "pages.main.sections.features.items.feature1.title"
        ),
        description: t(
          "pages.main.sections.features.items.feature1.description"
        ),
        imageDirection: Direction.RIGHT,
        imageSource: MappingScreenshot,
        bullets: [
          {
            icon: <GlobalOutlined />,
            title: t(
              "pages.main.sections.features.items.feature1.subItems.item1.title"
            ),
            text: t(
              "pages.main.sections.features.items.feature1.subItems.item1.description"
            ),
          },
          {
            icon: <DatabaseOutlined />,
            title: t(
              "pages.main.sections.features.items.feature1.subItems.item2.title"
            ),
            text: t(
              "pages.main.sections.features.items.feature1.subItems.item2.description"
            ),
          },
        ],
      },
      {
        title: t("pages.main.sections.features.items.feature2.title"),
        description: t(
          "pages.main.sections.features.items.feature2.description"
        ),
        imageDirection: Direction.LEFT,
        imageSource: AnalyticsScreenshot,
        bullets: [
          {
            icon: <FieldTimeOutlined />,
            title: t(
              "pages.main.sections.features.items.feature2.subItems.item1.title"
            ),
            text: t(
              "pages.main.sections.features.items.feature2.subItems.item1.description"
            ),
          },
          {
            icon: <ProjectOutlined />,
            title: t(
              "pages.main.sections.features.items.feature2.subItems.item2.title"
            ),
            text: t(
              "pages.main.sections.features.items.feature2.subItems.item2.description"
            ),
          },
        ],
      },
      {
        title: t("pages.main.sections.features.items.feature3.title"),
        description: t(
          "pages.main.sections.features.items.feature3.description"
        ),
        imageDirection: Direction.RIGHT,
        imageSource: VideoScreenshot,
        bullets: [
          {
            icon: <VideoCameraOutlined />,
            title: t(
              "pages.main.sections.features.items.feature3.subItems.item1.title"
            ),
            text: t(
              "pages.main.sections.features.items.feature3.subItems.item1.description"
            ),
          },
          {
            icon: <RadarChartOutlined />,
            title: t(
              "pages.main.sections.features.items.feature3.subItems.item2.title"
            ),
            text: t(
              "pages.main.sections.features.items.feature3.subItems.item2.description"
            ),
          },
        ],
      },
      {
        title: t("pages.main.sections.features.items.feature4.title"),
        description: t(
          "pages.main.sections.features.items.feature4.description"
        ),
        imageDirection: Direction.LEFT,
        imageSource: FilteringScreenshot,
        bullets: [
          {
            icon: <DashboardOutlined />,
            title: t(
              "pages.main.sections.features.items.feature4.subItems.item1.title"
            ),
            text: t(
              "pages.main.sections.features.items.feature4.subItems.item1.description"
            ),
          },
          {
            icon: <EnvironmentOutlined />,
            title: t(
              "pages.main.sections.features.items.feature4.subItems.item2.title"
            ),
            text: t(
              "pages.main.sections.features.items.feature4.subItems.item2.description"
            ),
          },
        ],
      },
    ];
  }, [t, language]);

  return (
    <CommonStyles.WrapperRow id={id}>
      <Col span={24}>
        <CommonStyles.FullyCenteredRow>
          <SectionHeader
            title={t("pages.main.sections.features.title")}
            subtitle={t("pages.main.sections.features.subtitle")}
          />
        </CommonStyles.FullyCenteredRow>

        <CommonStyles.FullyCenteredRow>
          {getFeatureItems().map((item: FeatureItemType) => (
            <CommonStyles.FullyCenteredRow>
              <FeatureItem item={item} />
            </CommonStyles.FullyCenteredRow>
          ))}
        </CommonStyles.FullyCenteredRow>
      </Col>
    </CommonStyles.WrapperRow>
  );
};

export default Features;
