import React, { useCallback } from "react";
import { Col, Row, Space } from "antd";
import {
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import * as S from "./FollowUs.style";
import * as CommonStyles from "@styles/CommonStyles.style";

const FollowUs: React.FC = () => {
  const { t } = useTranslation();

  const navigateTo = useCallback((path: string) => {
    window.open(path, "_blank");
  }, []);

  return (
    <Col span={24}>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <CommonStyles.Sn3Title>
          {t("footer.sections.followUs.title")}
        </CommonStyles.Sn3Title>
        <Row>
          <CommonStyles.IconWrapper
            onClick={() =>
              navigateTo("https://tr.linkedin.com/company/sinopsisio")
            }
          >
            <LinkedinOutlined />
          </CommonStyles.IconWrapper>

          <CommonStyles.IconWrapper
            onClick={() => navigateTo("https://twitter.com/sinopsisio")}
          >
            <TwitterOutlined />
          </CommonStyles.IconWrapper>

          <CommonStyles.IconWrapper
            onClick={() => navigateTo("https://www.instagram.com/sinopsis.io/")}
          >
            <InstagramOutlined />
          </CommonStyles.IconWrapper>
        </Row>
      </Space>
    </Col>
  );
};

export default FollowUs;
