import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "@app/common/enums";
import { ApiResponseState, License } from "@app/common/types";
import { fetchLicenses } from "@app/store/slices/Licence/thunks";

const initialState: ApiResponseState<License[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: undefined,
};

const licenseSlice = createSlice({
  name: "license",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLicenses.pending, (state, action) => {
        state.status = RequestStatus.IDLE;
      })
      .addCase(fetchLicenses.fulfilled, (state, action) => {
        state.status = RequestStatus.FULFILLED;
        state.data = action.payload;
      })
      .addCase(fetchLicenses.rejected, (state, action) => {
        state.status = RequestStatus.REJECTED;
      });
  },
});

export default licenseSlice.reducer;
