import React from "react";
import { BaseForm } from "@components/forms/BaseForm";
import SectionHeader from "@components/SectionHeader/SectionHeader";
import ContactForm from "../../ContactForm/ContactForm";
import * as CommonStyles from "@styles/CommonStyles.style";
import { Col } from "antd";
import { useTranslation } from "react-i18next";

interface IContactProps {
  id: string;
}

const Contact: React.FC<IContactProps> = ({ id }) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();

  return (
    <CommonStyles.WrapperRow id={id}>
      <CommonStyles.FullyCenteredRow>
        <Col span={24}>
          <CommonStyles.FullyCenteredRow>
            <SectionHeader
              title={t("pages.main.sections.contact.title")}
              subtitle={t("pages.main.sections.contact.subtitle")}
            />
          </CommonStyles.FullyCenteredRow>
        </Col>

        <Col span={24}>
          <CommonStyles.FullyCenteredRow>
            <ContactForm />
          </CommonStyles.FullyCenteredRow>
        </Col>
      </CommonStyles.FullyCenteredRow>
    </CommonStyles.WrapperRow>
  );
};

export default Contact;
