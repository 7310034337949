import React from "react";
import { Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import { LogoWithTitle } from "../LogoWithTitle";
import sinopsisLogo from "@assets/images/sinopsis_logo.svg";
import * as S from "./AboutUs.style";

const AboutUs: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Col span={24}>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Row>
          <LogoWithTitle title={"Sinopsis"} logo={sinopsisLogo} />
        </Row>
      </Space>
    </Col>
  );
};

export default AboutUs;
