import React, { useEffect, useState } from "react";
import { ExpandedHeader } from "@app/components/ExpandedHeader";
import { CollapsedHeader } from "@app/components/CollapsedHeader";
import { useResponsive } from "@common/hooks/useResponsive";

export interface IHeaderProps {
  siderCollapsed: boolean;
  toggleSider: () => void;
}

const Header: React.FC<IHeaderProps> = ({ toggleSider, siderCollapsed }) => {
  const { mobileOnly, tabletOnly } = useResponsive();

  return mobileOnly || tabletOnly ? (
    <CollapsedHeader
      siderCollapsed={siderCollapsed}
      toggleSider={toggleSider}
    />
  ) : (
    <ExpandedHeader />
  );
};

export default Header;
