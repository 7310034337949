import React from "react";
import { FeatureItemType } from "@common/types";
import { Col, Row } from "antd";
import * as S from "./FeatureBullet.style";
import * as CommonStyles from "@styles/CommonStyles.style";

interface IFeatureBulletListProps {
  item: FeatureItemType;
}

const FeatureBulletList: React.FC<IFeatureBulletListProps> = ({ item }) => {
  return (
    <S.ListContainer>
      {item.bullets.map((bullet, idx) => (
        <S.ItemContainer key={idx} gutter={[0,12]}>
          <Col xs={24} sm={24} md={4} lg={3} xl={2}>
            <CommonStyles.IconWrapper>{bullet.icon}</CommonStyles.IconWrapper>
          </Col>
          <Col xs={24} sm={24} md={20} lg={21} xl={22}>
            <CommonStyles.Sn3Title>{bullet.title}</CommonStyles.Sn3Title>
            <CommonStyles.Sn3Text $isLight>{bullet.text}</CommonStyles.Sn3Text>
          </Col>
        </S.ItemContainer>
      ))}
    </S.ListContainer>
  );
};

export default FeatureBulletList;
