import { getBrowserLanguage } from "@utils/utils";
import { LanguageType } from "@common/types";

const DEFAULT_LANGUAGE: LanguageType = "en";
const BROWSER_LANGUAGE: LanguageType = getBrowserLanguage();

export const persistLanguage = (langugage: LanguageType): void => {
  localStorage.setItem("lng", langugage);
};

export const getLanguage = (): LanguageType | undefined => {
  const language = localStorage.getItem("lng");
  return language ? (language as LanguageType) : undefined;
};
