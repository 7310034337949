import { NavigatorItemType } from "@app/common/types";
import { Space } from "antd";
import * as S from "./NavigatorItem.style";

interface INavigatorItemProps {
  item: NavigatorItemType;
  color?: string;
}

const HeaderItem: React.FC<INavigatorItemProps> = ({ item, color }) => {
  return (
    <S.LinkContainer $color={color}>
      <a onClick={item.onClick}>{item.title}</a>
    </S.LinkContainer>
  );
};

export default HeaderItem;
