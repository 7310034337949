import React from "react";
import { Col, Space } from "antd";
import { useTranslation } from "react-i18next";
import * as S from "./QuickLinks.style";
import * as CommonStyles from "@styles/CommonStyles.style";

const QuickLinks: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Col span={24}>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <CommonStyles.Sn3Title>
          {t("footer.sections.quickLinks.title")}
        </CommonStyles.Sn3Title>

        <Space direction="vertical" size="small" style={{ display: "flex" }}>
          <S.Link href="/contracts/privacy-policy">
            {t("footer.sections.quickLinks.privacyPolicy")}
          </S.Link>
          <S.Link href="/contracts/subscription-agreement">
            {t("footer.sections.quickLinks.subscriptionAgreement")}
          </S.Link>
          <S.Link href="https://sinopsis.io">Sinopsis</S.Link>
        </Space>
      </Space>
    </Col>
  );
};

export default QuickLinks;
