import React, { useCallback } from "react";
import { Col, Row } from "antd";
import { Direction } from "@common/enums";
import { FeatureItemType } from "@common/types";
import { useResponsive } from "@app/common/hooks/useResponsive";
import { FeatureBulletList } from "../FeatureBulletList";
import * as S from "./FeatureItem.style";
import * as CommonStyles from "@styles/CommonStyles.style";

interface FeatureItemProps {
  item: FeatureItemType;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ item }) => {
  const { mobileOnly } = useResponsive();

  const renderListSection = useCallback(() => {
    return (
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <S.FeatureContainer>
          <CommonStyles.Sn2Title>{item.title}</CommonStyles.Sn2Title>
          <CommonStyles.Sn2Text $isLight>
            {item.description}
          </CommonStyles.Sn2Text>
          <FeatureBulletList item={item} />
        </S.FeatureContainer>
      </Col>
    );
  }, [item]);

  const renderImageSection = useCallback(() => {
    return (
      <Col style={{padding: "1rem"}} xs={24} sm={24} md={12} lg={12} xl={12}>
        <S.ScreenShotContainer>
          <S.ScreenShot src={item.imageSource} />
        </S.ScreenShotContainer>
      </Col>
    );
  }, [item]);

  const renderImageLeft = useCallback(() => {
    return (
      <React.Fragment>
        {renderImageSection()}
        {renderListSection()}
      </React.Fragment>
    );
  }, [item]);

  const renderImageRight = useCallback(() => {
    return (
      <React.Fragment>
        {renderListSection()}
        {renderImageSection()}
      </React.Fragment>
    );
  }, [item]);

  return (
    <Row justify="center" align="middle">
      {mobileOnly && renderImageLeft()}
      {!mobileOnly &&
        item.imageDirection === Direction.RIGHT &&
        renderImageRight()}
      {!mobileOnly &&
        item.imageDirection === Direction.LEFT &&
        renderImageLeft()}
    </Row>
  );
};

export default FeatureItem;
