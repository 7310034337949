import styled, { css } from "styled-components";

interface ICustomContainerProps {
  $margin?: string;
  $padding?: string;
}

export const CustomContainer = styled.div<ICustomContainerProps>`
  ${({ $margin }) => css`
    margin: ${$margin};
  `}

  ${({ $padding }) => css`
    padding: ${$padding};
  `}
`;
