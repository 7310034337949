import React from "react";
import * as CommonStyles from "@styles/CommonStyles.style";
import { PrivacyPolicy } from "../components/PrivacyPolicy";

const PrivacyPolicyPage: React.FC = () => {
  return (
    <CommonStyles.WrapperRow>
      <PrivacyPolicy />
    </CommonStyles.WrapperRow>
  );
};

export default PrivacyPolicyPage;
