import { FONT_SIZE } from "./../../../common/constants";
import styled from "styled-components";
import { Col, Layout, Row } from "antd";
import { media } from "@common/constants";
import { BurgerIcon } from "@app/components/Burger/Burger";

export const Sider = styled(Layout.Sider)`
  position: fixed;
  overflow: visible;
  right: 0;
  z-index: 5;
  min-height: 100vh;
  max-height: 100vh;

  color: var(--text-secondary-color);
`;

export const CollapseIconContainer = styled(Row)`
  justify-content: flex-end;
  padding: 2rem 1.25rem;
`

export const SiderContentContainer = styled(Row)`
  justify-content: center;
  align-items: center;
`;

export const SiderItem = styled(Row)`
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--text-secondary-color);
  margin: 1rem 0;
  text-align: center;

  & > a {
    color: white;
    font-size: ${FONT_SIZE.xl};
  }

  & > a:hover {
    color: white;
    font-size: ${FONT_SIZE.xl};
  }

  &::after {
    content: "";
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

export const IconWrapper = styled(Col)`
  display: flex;
  z-index: 999;
`;

export const MobileBurger = styled(BurgerIcon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: -0.5rem;
  color: yellow;
`;
