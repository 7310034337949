import React from "react";
import { Layout } from "antd";
import { Footer } from "@app/components/Footer";

const MainFooter: React.FC = () => {
  return (
    <Layout.Footer>
      <Footer />
    </Layout.Footer>
  );
};

export default MainFooter;
