import React from "react";
import { useTranslation } from "react-i18next";
import * as CommonStyles from "@styles/CommonStyles.style";
import * as S from "./PrivacyPolicy.style";

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();

  return (
    <S.CustomContainer $margin="1rem 0">
      {/* Privacy Policy Header and Intro */}
      <S.CustomContainer $margin="0 0 2rem 0">
        <S.CustomContainer $margin="0 0 1rem 0">
          <CommonStyles.Sn1Title>
            {t("contracts.privacyPolicy.title")}
          </CommonStyles.Sn1Title>
        </S.CustomContainer>

        <CommonStyles.Sn2Text $isLight>
          {t("contracts.privacyPolicy.intro")}
        </CommonStyles.Sn2Text>
      </S.CustomContainer>

      {/* Contact Information title and intro */}
      <S.CustomContainer $margin="0 0 2rem 0">
        <S.CustomContainer $margin="0 0 1rem 0">
          <CommonStyles.Sn3Title>
            {t("contracts.privacyPolicy.contactInformation.title")}
          </CommonStyles.Sn3Title>
        </S.CustomContainer>

        <CommonStyles.Sn2Text $isLight>
          {t("contracts.privacyPolicy.contactInformation.intro")}
        </CommonStyles.Sn2Text>

        <S.CustomContainer $margin="1rem 0">
          <CommonStyles.Sn2Text $isLight style={{ display: "block" }}>
            1.{" "}
            {t(
              "contracts.privacyPolicy.contactInformation.bullets.questionsAndComments.title"
            )}
          </CommonStyles.Sn2Text>

          <CommonStyles.Sn2Text $isLight style={{ display: "block" }}>
            {t(
              "contracts.privacyPolicy.contactInformation.bullets.questionsAndComments.text"
            )}
          </CommonStyles.Sn2Text>
        </S.CustomContainer>
      </S.CustomContainer>

      {/* Collected Information title and intro */}
      <S.CustomContainer $margin="0 0 2rem 0">
        <S.CustomContainer $margin="0 0 1rem 0">
          <CommonStyles.Sn3Title>
            {t("contracts.privacyPolicy.collectedInformation.title")}
          </CommonStyles.Sn3Title>
        </S.CustomContainer>

        <CommonStyles.Sn2Text $isLight>
          {t("contracts.privacyPolicy.collectedInformation.intro")}
        </CommonStyles.Sn2Text>

        <S.CustomContainer $margin="1rem 0">
          <CommonStyles.Sn2Text $isLight style={{ display: "block" }}>
            1.{" "}
            {t(
              "contracts.privacyPolicy.collectedInformation.bullets.customerProvidedData.title"
            )}
          </CommonStyles.Sn2Text>

          <CommonStyles.Sn2Text $isLight style={{ display: "block" }}>
            {t(
              "contracts.privacyPolicy.collectedInformation.bullets.customerProvidedData.text"
            )}
          </CommonStyles.Sn2Text>
        </S.CustomContainer>

        <S.CustomContainer $margin="1rem 0">
          <CommonStyles.Sn2Text $isLight style={{ display: "block" }}>
            2.{" "}
            {t(
              "contracts.privacyPolicy.collectedInformation.bullets.customerData.title"
            )}
          </CommonStyles.Sn2Text>

          <CommonStyles.Sn2Text $isLight style={{ display: "block" }}>
            {t(
              "contracts.privacyPolicy.collectedInformation.bullets.customerData.text"
            )}
          </CommonStyles.Sn2Text>
        </S.CustomContainer>

        <S.CustomContainer $margin="1rem 0">
          <CommonStyles.Sn2Text $isLight style={{ display: "block" }}>
            3.{" "}
            {t(
              "contracts.privacyPolicy.collectedInformation.bullets.otherData.title"
            )}
          </CommonStyles.Sn2Text>

          <CommonStyles.Sn2Text $isLight style={{ display: "block" }}>
            {t(
              "contracts.privacyPolicy.collectedInformation.bullets.otherData.text"
            )}
          </CommonStyles.Sn2Text>
        </S.CustomContainer>
      </S.CustomContainer>

      {/* Use Of Personal Data title and text */}
      <S.CustomContainer $margin="0 0 2rem 0">
        <S.CustomContainer $margin="0 0 1rem 0">
          <CommonStyles.Sn3Title>
            {t("contracts.privacyPolicy.useOfPersonalData.title")}
          </CommonStyles.Sn3Title>
        </S.CustomContainer>

        <CommonStyles.Sn2Text $isLight>
          {t("contracts.privacyPolicy.useOfPersonalData.intro")}
        </CommonStyles.Sn2Text>

        <S.CustomContainer $margin="1rem 0">
          <CommonStyles.Sn2Text $isLight style={{ display: "block" }}>
            1.{" "}
            {t(
              "contracts.privacyPolicy.useOfPersonalData.bullets.customerProvidedData.title"
            )}
          </CommonStyles.Sn2Text>

          <S.CustomContainer $margin="0.5rem 0 0.5rem 2rem">
            <S.BulletPoint>&#8226;</S.BulletPoint>
            <CommonStyles.Sn2Text $isLight>
              {t(
                "contracts.privacyPolicy.useOfPersonalData.bullets.customerProvidedData.1"
              )}
            </CommonStyles.Sn2Text>
          </S.CustomContainer>

          <S.CustomContainer $margin="0.5rem 0 0.5rem 2rem">
            <S.BulletPoint>&#8226;</S.BulletPoint>
            <CommonStyles.Sn2Text $isLight>
              {t(
                "contracts.privacyPolicy.useOfPersonalData.bullets.customerProvidedData.2"
              )}
            </CommonStyles.Sn2Text>
          </S.CustomContainer>

          <S.CustomContainer $margin="0.5rem 0 0.5rem 2rem">
            <S.BulletPoint>&#8226;</S.BulletPoint>
            <CommonStyles.Sn2Text $isLight>
              {t(
                "contracts.privacyPolicy.useOfPersonalData.bullets.customerProvidedData.3"
              )}
            </CommonStyles.Sn2Text>
          </S.CustomContainer>

          <S.CustomContainer $margin="0.5rem 0 0.5rem 2rem">
            <S.BulletPoint>&#8226;</S.BulletPoint>
            <CommonStyles.Sn2Text $isLight>
              {t(
                "contracts.privacyPolicy.useOfPersonalData.bullets.customerProvidedData.4"
              )}
            </CommonStyles.Sn2Text>
          </S.CustomContainer>

          <S.CustomContainer $margin="0.5rem 0 0.5rem 2rem">
            <S.BulletPoint>&#8226;</S.BulletPoint>
            <CommonStyles.Sn2Text $isLight>
              {t(
                "contracts.privacyPolicy.useOfPersonalData.bullets.customerProvidedData.5"
              )}
            </CommonStyles.Sn2Text>
          </S.CustomContainer>

          <S.CustomContainer $margin="0.5rem 0 0.5rem 2rem">
            <S.BulletPoint>&#8226;</S.BulletPoint>
            <CommonStyles.Sn2Text $isLight>
              {t(
                "contracts.privacyPolicy.useOfPersonalData.bullets.customerProvidedData.6"
              )}
            </CommonStyles.Sn2Text>
          </S.CustomContainer>

          <S.CustomContainer $margin="0.5rem 0 0.5rem 2rem">
            <S.BulletPoint>&#8226;</S.BulletPoint>
            <CommonStyles.Sn2Text $isLight>
              {t(
                "contracts.privacyPolicy.useOfPersonalData.bullets.customerProvidedData.7"
              )}
            </CommonStyles.Sn2Text>
          </S.CustomContainer>
        </S.CustomContainer>

        <S.CustomContainer $margin="1rem 0">
          <CommonStyles.Sn2Text $isLight style={{ display: "block" }}>
            2.{" "}
            {t(
              "contracts.privacyPolicy.useOfPersonalData.bullets.customerData.title"
            )}
          </CommonStyles.Sn2Text>

          <S.CustomContainer $margin="0.5rem 0 0.5rem 2rem">
            <S.BulletPoint>&#8226;</S.BulletPoint>
            <CommonStyles.Sn2Text $isLight>
              {t(
                "contracts.privacyPolicy.useOfPersonalData.bullets.customerData.1"
              )}
            </CommonStyles.Sn2Text>
          </S.CustomContainer>

          <S.CustomContainer $margin="0.5rem 0 0.5rem 2rem">
            <S.BulletPoint>&#8226;</S.BulletPoint>
            <CommonStyles.Sn2Text $isLight>
              {t(
                "contracts.privacyPolicy.useOfPersonalData.bullets.customerData.2"
              )}
            </CommonStyles.Sn2Text>
          </S.CustomContainer>

          <S.CustomContainer $margin="0.5rem 0 0.5rem 2rem">
            <S.BulletPoint>&#8226;</S.BulletPoint>
            <CommonStyles.Sn2Text $isLight>
              {t(
                "contracts.privacyPolicy.useOfPersonalData.bullets.customerData.3"
              )}
            </CommonStyles.Sn2Text>
          </S.CustomContainer>

          <S.CustomContainer $margin="0.5rem 0 0.5rem 2rem">
            <S.BulletPoint>&#8226;</S.BulletPoint>
            <CommonStyles.Sn2Text $isLight>
              {t(
                "contracts.privacyPolicy.useOfPersonalData.bullets.customerData.4"
              )}
            </CommonStyles.Sn2Text>
          </S.CustomContainer>

          <S.CustomContainer $margin="0.5rem 0 0.5rem 2rem">
            <S.BulletPoint>&#8226;</S.BulletPoint>
            <CommonStyles.Sn2Text $isLight>
              {t(
                "contracts.privacyPolicy.useOfPersonalData.bullets.customerData.5"
              )}
            </CommonStyles.Sn2Text>
          </S.CustomContainer>

          <S.CustomContainer $margin="0.5rem 0 0.5rem 2rem">
            <S.BulletPoint>&#8226;</S.BulletPoint>
            <CommonStyles.Sn2Text $isLight>
              {t(
                "contracts.privacyPolicy.useOfPersonalData.bullets.customerData.6"
              )}
            </CommonStyles.Sn2Text>
          </S.CustomContainer>

          <S.CustomContainer $margin="0.5rem 0 0.5rem 2rem">
            <S.BulletPoint>&#8226;</S.BulletPoint>
            <CommonStyles.Sn2Text $isLight>
              {t(
                "contracts.privacyPolicy.useOfPersonalData.bullets.customerData.7"
              )}
            </CommonStyles.Sn2Text>
          </S.CustomContainer>
        </S.CustomContainer>

        <S.CustomContainer $margin="1rem 0">
          <CommonStyles.Sn2Text $isLight style={{ display: "block" }}>
            3.{" "}
            {t(
              "contracts.privacyPolicy.useOfPersonalData.bullets.otherData.title"
            )}
          </CommonStyles.Sn2Text>
          <S.CustomContainer $margin="0.5rem 0 0.5rem 2rem">
            <S.BulletPoint>&#8226;</S.BulletPoint>
            <CommonStyles.Sn2Text $isLight>
              {t(
                "contracts.privacyPolicy.useOfPersonalData.bullets.otherData.1"
              )}
            </CommonStyles.Sn2Text>
          </S.CustomContainer>
          <S.CustomContainer $margin="0.5rem 0 0.5rem 2rem">
            <S.BulletPoint>&#8226;</S.BulletPoint>
            <CommonStyles.Sn2Text $isLight>
              {t(
                "contracts.privacyPolicy.useOfPersonalData.bullets.otherData.2"
              )}
            </CommonStyles.Sn2Text>
          </S.CustomContainer>
        </S.CustomContainer>
      </S.CustomContainer>

      {/* Cookies Information title and text */}
      <S.CustomContainer $margin="0 0 2rem 0">
        <S.CustomContainer $margin="0 0 1rem 0">
          <CommonStyles.Sn3Title>
            {t("contracts.privacyPolicy.cookies.title")}
          </CommonStyles.Sn3Title>
        </S.CustomContainer>

        <CommonStyles.Sn2Text $isLight>
          {t("contracts.privacyPolicy.cookies.text")}
        </CommonStyles.Sn2Text>
      </S.CustomContainer>

      {/* Children under 13 Information title and text */}
      <S.CustomContainer $margin="0 0 2rem 0">
        <S.CustomContainer $margin="0 0 1rem 0">
          <CommonStyles.Sn3Title>
            {t("contracts.privacyPolicy.childrenUnder13.title")}
          </CommonStyles.Sn3Title>
        </S.CustomContainer>

        <CommonStyles.Sn2Text $isLight>
          {t("contracts.privacyPolicy.childrenUnder13.text")}
        </CommonStyles.Sn2Text>
      </S.CustomContainer>

      {/* Adaptation Information title and text */}
      <S.CustomContainer $margin="0 0 2rem 0">
        <S.CustomContainer $margin="0 0 1rem 0">
          <CommonStyles.Sn3Title>
            {t("contracts.privacyPolicy.adaptation.title")}
          </CommonStyles.Sn3Title>
        </S.CustomContainer>

        <CommonStyles.Sn2Text $isLight>
          {t("contracts.privacyPolicy.adaptation.text")}
        </CommonStyles.Sn2Text>
      </S.CustomContainer>

      {/* Sinopsis As Data Processor Information title and text */}
      <S.CustomContainer $margin="0 0 2rem 0">
        <S.CustomContainer $margin="0 0 1rem 0">
          <CommonStyles.Sn3Title>
            {t("contracts.privacyPolicy.sinopsisAsDataProcessor.title")}
          </CommonStyles.Sn3Title>
        </S.CustomContainer>

        <CommonStyles.Sn2Text $isLight>
          {t("contracts.privacyPolicy.sinopsisAsDataProcessor.text")}
        </CommonStyles.Sn2Text>
      </S.CustomContainer>

      {/* Changes on Privacy Policy title and text */}
      <S.CustomContainer $margin="0 0 2rem 0">
        <S.CustomContainer $margin="0 0 1rem 0">
          <CommonStyles.Sn3Title>
            {t("contracts.privacyPolicy.changesOnPrivacyPolicy.title")}
          </CommonStyles.Sn3Title>
        </S.CustomContainer>

        <CommonStyles.Sn2Text $isLight>
          {t("contracts.privacyPolicy.changesOnPrivacyPolicy.text")}
        </CommonStyles.Sn2Text>
      </S.CustomContainer>
    </S.CustomContainer>
  );
};

export default PrivacyPolicy;
