import styled from "styled-components";
import { Row } from "antd";

export const ListContainer = styled.div`
  margin: 2rem 0;
`;

export const ItemContainer = styled(Row)`
  margin: 1rem 0;
`;
